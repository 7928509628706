<template>
  <div class=" d-flex flex-wrap">
    <left-column :formFields="formFields"></left-column>
    <div id="right-column" class="p-5">
      <div id="header" class="d-flex justify-content-between">
        <h1>Fields Generator</h1>
        <generate-fields-button class="" :generateFields="generateFields"/>
      </div>

      <existing-fields :existingFields="existingFields" @checked="check"/>

<table class="table">
  <thead>
    <tr>
      <th scope="col"><input type="checkbox" @click="disableAll">&nbsp;Disable user editing</th>
      <th scope="col">Section</th>
      <th scope="col">Block</th>
      <th scope="col">Field Type</th>
      <th scope="col">Field Type Number</th>
      <th scope="col">Add</th>
      <th scope="col">Remove</th>
    </tr>
  </thead>
  <tbody>
    <tr id="form-field" v-for="(formField, index) in formFields" :key="formField.index">
      <th scope="row"><td><input type="checkbox" v-model="formFields[index].disableUserEditing"></td></th>
      <td class="form-floating">
          <input type="number" class="form-control" id="floatingInput" placeholder="1" v-model="formField.section" required/>
          <label for="floatingInput">Section</label>
        </td>
      
        <td class="form-floating">
          <input type="number" class="form-control" id="floatingInput" placeholder="1" v-model="formField.block" required/>
          <label for="floatingInput">Block</label>
        </td>

      <td class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="formField.fieldType" required>
            <option value="Copy">Copy</option>
            <option value="Text">Text</option>
            <option value="Image">Image</option>
            <option value="Video URL">Video URL</option>
            <option value="Video Image">Video Image</option>
            <option value="CTA Text">CTA Text</option>
            <option value="CTA URL">CTA URL</option>
            <option value="Animation URL">Animation URL</option>
            <option value="Asset URL">Asset</option>
          </select>
          <label for="floatingSelect">Field Type</label>
        </td>

       <td class="form-floating ">
          <input
            type="number"
            class="form-control"
            id="floatingInput"
            placeholder="1"
            v-model="formField.fieldTypeNumber"
            required
          />
          <label for="floatingInput">Field Type Number</label>
        </td>

       <td>
          <button class="btn round" type="submit" @click="addField()">+</button>
        </td>
        <td>
          <button class="btn round" type="submit" @click="removeField(index)">-</button>
        </td>
    </tr>
  </tbody>
</table>
      <generate-fields-button class="d-flex mx-auto" :generateFields="generateFields"/>
      </div>
    </div>
  
</template>

<script>
//importing existing JSON fields
import GeneralSetup from "./assets/fields-general-setup.json"
import PartnerDetails from "./assets/fields-partner-details.json"
import FormFields from "./assets/fields-form-fields-en-us.json"
import PopupContactForm from "./assets/fields-popup-content-contact-form.json"
import PopupContactConfirmation from "./assets/fields-popup-content-contact-confirmation.json"
import PopupAssetForm from "./assets/fields-popup-content-asset-form.json"
import PopupAssetDownload from "./assets/fields-popup-content-asset-download.json"
import DownloadableAssets from "./assets/fields-downloadable-assets.json"
import CustomAssets from "./assets/fields-downloadable-assets.json"
import AboutSection from "./assets/fields-microsite-content-about-section.json"
import ContactSection from "./assets/fields-microsite-content-contact-form-section.json"


//importing components
import GenerateFieldsButton from "./components/GenerateFieldsButton.vue";
import leftColumn from "./components/LeftColumn.vue";
import ExistingFields from './components/ExistingFields.vue'


export default {
  components: { leftColumn, GenerateFieldsButton, ExistingFields },
  data() {
    return {
      disableAllFields: false,
      formFields: [
        {
          disableUserEditing: false, 
          section: "",
          block: "",
          fieldType: "",
          fieldTypeNumber: "",
        },
      ],
      existingFields:[
          {
            name: "General Setup",
            include: 'false',
            json: GeneralSetup
          },
          {
            name: "Partner Details",
            include: 'false',
            json: PartnerDetails
          },
          {
            name: "Form Fields (en-us)",
            include: 'false',
            json: FormFields
          },
          {
            name: "Popup Contact Form",
            include: 'false',
            json: PopupContactForm
          },
          {
            name: "Popup Contact Confirmation",
            include: 'false',
            json: PopupContactConfirmation
          },
          {
            name: "Popup Asset Form",
            include: 'false',
            json: PopupAssetForm
          },
          {
            name: "Popup Asset Download",
            include: 'false',
            json: PopupAssetDownload
          },
          {
            name: "Downloadable Assets",
            include: 'false',
            json: DownloadableAssets
          },
          {
            name: "Custom Assets",
            include:'false',
            json: CustomAssets
          },
          {
            name:"About Section",
            include: 'false',
            json: AboutSection
          },
          {
            name: "Contact Section",
            include: 'false',
            json: ContactSection
          }
      ]
    };
  },
  methods: {
    check(index) {
      if(this.existingFields[index].include != true){
        this.existingFields[index].include = true;
      }
      else{
        this.existingFields[index].include = false;
      }
    },
    disableAll()
    {
      if(this.disableAllFields == false){
          this.disableAllFields = true;
          for(var i=0; i < this.formFields.length; i++){
            this.formFields[i].disableUserEditing= true;
          }
      }
      else{
        this.disableAllFields = false;
          for(var j=0; j < this.formFields.length; j++){
            this.formFields[j].disableUserEditing= false;
          }
      }
    },
    addField() {
      this.formFields.push({
        section: "",
        block: "",
        fieldType: "",
        fieldTypeNumber: "",
      });
    },
    removeField(index) {
      this.formFields.splice(index, 1);
    },
    generateFields() {
      //var fieldsExisting = `{
    //'fields': [

    //]
//}`;
      var myJSON = [];
      var index=0
      for(var i=0; i < this.existingFields.length; i++){
        
        if(this.existingFields[i].include == true){
          myJSON[index] = JSON.stringify(this.existingFields[i].json.fields); 
          index++
        }
      }
      var finalJSON = '';
      if (myJSON.length > 0)
      {
        finalJSON = myJSON.join(",") + ',';
        finalJSON = finalJSON.replace(/\[/g, '').replace(/]/g, '');
      }


      var result = [];
      var fieldsLength = this.formFields.length;

      for (let count = 0; count < fieldsLength; count++) {
        var partnerEditable = !(this.formFields[count].disableUserEditing);
        var inputSection = this.formFields[count].section;
        var inputBlock = this.formFields[count].block;
        var inputFieldType = this.formFields[count].fieldType;
        var inputFieldTypeNumber = this.formFields[count].fieldTypeNumber;
        var fieldType;
        var defaults;
        //--Checking if the field is header--
        if (
          count == 0 ||
          this.formFields[count].section != this.formFields[count - 1].section
        ) {
          result.push(`{
                                "toIgnorePartnerDefaults": false,
                                "hiddenOnLead": false,
                                "required": false,
                                "hiddenOnContacts": false,
                                "valuesExtended": "",
                                "dataType": "text",
                                "otherDisplayOption": false,
                                "defaults": "",
                                "supplierPartnerEditable": false,
                                "name": "Microsite Content - Section ${this.formFields[count].section}",
                                "partnerEditable": true,
                                "fieldType": "header",
                                "availableTo123AddContent": false,
                                "displayName": "Microsite Content - Section ${this.formFields[count].section}",
                                "internalField": false,
                                "values": "",
                                "referenceType": "Panel",
                                "editableByAgency": false,
                                "availableToMarcom": true,
                                "contextualField": true,
                                "hiddenOption": "",
                                "viewableByAgency": false
                            }`);
        }

        //Checking if any fields are empty
        if (
          inputSection != "" &&
          inputBlock != "" &&
          inputFieldType != "" &&
          inputFieldTypeNumber != ""
        ) {
          //checking if the field is URL, text, copy or embedded image
          if (inputFieldType == "Text" || inputFieldType == "CTA Text") {
            fieldType = "edit";
            defaults = "Ipsum Lorem";
          } else if (inputFieldType == "Copy") {
            fieldType = "richtext";
            defaults =
              "Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod Lorem ipsum dolor sit amet eiusmod";
          } else if (
            inputFieldType == "Image" ||
            inputFieldType == "Video Image"
          ) {
            fieldType = "embeddedimage";
            defaults =
              "https://static.ziftsolutions.com/files/8a99822376ee391b0176f904644e3fbe/prezi.gif";
          } else if (
            inputFieldType == "Video URL" ||
            inputFieldType == "CTA URL" ||
            inputFieldType == "Animation URL" ||
            inputFieldType == "Asset URL"
          ) {
            fieldType = "url";
            defaults = "#";
          } else {
            alert("Nothing");
          }

          var fieldName =
            "Section " +
            inputSection +
            " Block " +
            inputBlock +
            " " +
            inputFieldType +
            " " +
            inputFieldTypeNumber;
          result.push(`{
                                "availableTo123AddContent": false,
                                "availableToMarcom": true,
                                "contextualField": true,
                                "dataType": "text",
                                "defaults": "${defaults}",
                                "displayName": "${fieldName}",
                                "editableByAgency": false,
                                "fieldType": "${fieldType}",
                                "hiddenOnContacts": false,
                                "hiddenOnLead": false,
                                "hiddenOption": "",
                                "internalField": false,
                                "name": "${fieldName}",
                                "otherDisplayOption": false,
                                "partnerEditable": ${partnerEditable},
                                "referenceType": "Panel",
                                "required": false,
                                "supplierPartnerEditable": false,
                                "toIgnorePartnerDefaults": true,
                                "values": "",
                                "valuesExtended": "",
                                "viewableByAgency": false
                            }`);
        }
      }

      //creating a textbox to paste the final result
      var el = document.createElement("textarea");
      el.setAttribute("readOnly", "");
      el.style.position = "absolute";
      el.style.left = "-4000px";

      el.value = `{
                        "fields": [
                            ` +
                            finalJSON +
        result.join(",") +
        `]
                        }`;

      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      alert("JSON code has been copied. Please paste it to a code editor.");
    },
  },
  computed: {},
};
</script>

<style>
</style>
