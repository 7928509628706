<template>
  <button class="btn" type="submit" @click="generateFields()">
        Generate Fields
    </button>
</template>

<script>
export default {
    name:'GenerateFieldsButton',
    props:{
        generateFields: {type: Function}
    }
}
</script>

<style>

</style>