<template>
  <div id="left-column" class="w-30 p-4">
        <div id="form-field" class="d-flex align-items-center" v-for="(formField, index) in formFields" :key="formField.index">
          <ul>
            <h4 v-if="index==0">Section {{formField.section}} Contents</h4>
            <h4 v-if="index>0 && formField.section != formFields[index-1].section">Section {{formField.section}} Contents</h4>
            <li> Section {{formField.section}} Block {{formField.block}} {{formField.fieldType}} {{formField.fieldTypeNumber}}</li>
          </ul>
          
        </div>
         
      </div>
      
</template>

<script>
export default {
    name:'leftColumn',
    props: ["formFields"]
}
</script>

<style scoped>
  *{
    color: white;
  }

  h4{
    padding: 10px 0;
  }
</style>