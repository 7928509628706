<template>
  <div id="existing-fields">
        <div class="existing-field" v-for="(existingField, index) in existingFields" :key="existingField.name">
          <input type="checkbox" @click="checked(index)"> {{existingField.name}}
        </div>
      </div>
</template>

<script>
export default {
    name: 'existing-fields',
    props: ["existingFields"],
    emits: ["checked"],
    methods:{
        checked(index){
            this.$emit("checked", index);
        }
    }
}
</script>

<style>

</style>